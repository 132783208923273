























import { defineComponent, ref, watch } from '@vue/composition-api'
import { xStartupOrder } from '@/GeneratedTypes/xOrder/xStartupOrder'
import OrderDetail from '@/components/OrderHistory/OrderDetail.vue'
import Loading from '@/elements/Loading.vue'
import { loadOrder } from '@/lib/components/OrderDetail/loadOrder'
import { OrderInfoExt } from '@/models/Order/OrderInfoExt'

export default defineComponent({
  name: 'OrderDetailPage',
  components: { Loading, OrderDetail },
  setup(props, ctx) {
    const currentOrder = ref<xStartupOrder | null>(null)
    const currentOrderInfo = ref<OrderInfoExt | null>(null)
    const errorMessage = ref('')
    const loading = ref(false)
    watch(
      () => ctx.root.$route,
      () => getOrderByRoute(),
      { immediate: true }
    )

    async function getOrderByRoute() {
      errorMessage.value = ''
      const league = ctx.root.$route.params.id ?? ''
      const orderid = ctx.root.$route.params.orderid ?? ''
      try {
        loading.value = true
        await loadOrder(league, orderid, currentOrder, currentOrderInfo, errorMessage)
      } catch (e) {
        const message = e.message || e.errorObject?.message
        errorMessage.value = `Problem loading the order detail, please contact support ${
          message ? `(${message})` : ''
        }
        `
        throw e
      } finally {
        loading.value = false
      }
    }
    return { currentOrder, loading, errorMessage, currentOrderInfo }
  },
})
